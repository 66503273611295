<template>
  <el-dialog title="Export" :visible.sync="value">
    <div class="pb-20">Select date range for export data</div>
    <el-date-picker
      v-model="range"
      type="daterange"
      align="right"
      unlink-panels
      format="yyyy/MM/dd"
      value-format="yyyy-MM-dd"
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      :picker-options="pickerOptions">
    </el-date-picker>
    <span slot="footer" class="dialog-footer flex gaps">
      <el-button @click="$emit('close')">Cancel</el-button>
      <download-excel
          v-if="range !== ''"
          :fetch="fetchData"
          :before-finish = "finishDownload"
          :fields="json_fields"
          worksheet="My Worksheet"
          :name="name">
        <el-button>Download Data</el-button>
      </download-excel>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Export',

  props: {
    dialogExportVisible: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },

  data () {
    return {
      json_fields: {
        invoice: 'invoiceId',
        saleDate: {
          field: 'saleDate',
          callback: (value) => {
            let d = new Date(value)
            let minutes = d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes()
            let hours = d.getHours()
            return this.formatDate(value) + '   ' + hours + ':' + minutes
            // return new Date(new Date(value).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)
          }
        },
        date: {
          field: 'day',
          callback: (value) => {
            return this.formatDate(value)
          }
        },
        sessionTime: 'sessionTime',
        sessionName: 'sessionName',
        clients: {
          field: 'clientNames',
          callback: (value) => {
            return value.join(', ')
          }
        },
        type: 'typeName',
        discountType: 'discountType',
        discountPercent: 'discountPercent',
        discountMemo: 'discountMemo',
        persons: 'persons',
        price: 'cost',
        isBackToBack: 'isBackToBack',
        isDoubleSession: 'isDoubleSession',
        isBooking: 'isBooking',
        isClose: 'isClose',
        isRefund: 'isRefund'
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      range: '',
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: 'Last week',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last month',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last 3 months',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },

  computed: {
    ...mapState({
      companies: state => state.company.companies
    }),
    value: {
      get () {
        return this.dialogExportVisible
      },
      set () {
        return this.$emit('close')
      }
    },
    name () {
      return this.range !== '' && this.range.length === 2
        ? 'Tickets_export_from_' + this.range[0] + '_to_' + this.range[1] + '.xls'
        : 'error.xls'
    }
  },

  methods: {
    ...mapActions(['getTicketsByRange']),
    async fetchData () {
      const response = await this.getTicketsByRange(this.range)
      return response
    },
    finishDownload () {
      this.$emit('close')
    },
    formatDate (date) {
      return new Date(new Date(date).toString().split('GMT')[0] + ' UTC')
        .toISOString().slice(0, 10)
    }
  }
}
</script>

<style lang="scss">
</style>
