<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">TICKETS REPORT</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <div class="flex gaps mv-10 justify-space-between">
        <div class="box left center">
          <el-input placeholder="Search"
                    size="small"
                    ref="search"
                    v-model="search"
                    style="width: 200px;"
                    @input="handleSearchInput"
                    class="box center"></el-input>
        </div>
        <div class="box grow left flex gaps">
          <el-date-picker
            :append-to-body="false"
            size="small"
            v-model="dateSearch"
            type="date"
            placeholder="Pick a day"
            @change="onChangeDate"
            :picker-options="pickerOptions">
          </el-date-picker>
          <el-select size="small" v-model="sessionSearch" placeholder="Select">
            <el-option
              v-for="item in sessionsForSelect"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!-- <div class="box grow left">

        </div> -->
        <div class="box rigth">
          <el-button size="small" @click="dialogExportVisible = true">export</el-button>
        </div>
    </div>
    <div :style="{width: tableWidth + 'px'}">
      <el-table
        show-summary
        :summary-method="getSummaries"
        :row-class-name="tableRowClassName"
        :max-height="tableHeight"
        border
        :data="filtered">
        <el-table-column
          prop="invoiceId"
          width="80"
          label="Invoice">
          <template #default="scope">
            <el-button
              @click.native.prevent="$onCommandParams('invoice', { id: scope.row.invoiceId })"
              type="text" size="small">{{scope.row.invoiceId}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          width="165"
          label="Sale Date">
          <template #default="scope">
            {{formatDateTime(scope.row.saleDate)}}
          </template>
        </el-table-column>
        <el-table-column
          width="110"
          label="Date">
          <template #default="scope">
            {{formatDate(scope.row.day)}}
          </template>
        </el-table-column>
        <el-table-column
          width="110"
          prop="sessionTime"
          label="Time">
        </el-table-column>
        <el-table-column
           prop="sessionName"
          label="Session Name (index)">
          <template #default="scope">
            {{scope.row.sessionName}} ({{scope.row.sessionIds.join(', ')}})
          </template>
        </el-table-column>
        <el-table-column
          label="Clients">
            <template #default="scope">
              <div v-for="(w, index) in scope.row.clientNames" :key="index" class="info-text fs-12">
                {{w}}
              </div>
              <template v-if="scope.row.persons - scope.row.clientNames.length > 0">
                <div class="accent-text fs-12" v-for="(c, idx) in (scope.row.persons - scope.row.clientNames.length)" :key="idx +'-no'">
                  No waiver
                </div>
              </template>
            </template>
        </el-table-column>
        <el-table-column
          prop="typeName"
          label="Type">
        </el-table-column>
        <el-table-column
          width="150"
          label="Discount">
            <template #default="scope">
              <div class="warning-text fs-12" v-if="scope.row.discountPercent > 0">
                Discount {{scope.row.discountPercent}} %
              </div>
              <div class="fs-12" v-if="scope.row.discountPercent > 0">{{scope.row.discountType}} {{scope.row.discountMemo}}</div>
            </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="persons"
          width="70"
          label="Pers">
        </el-table-column>
        <el-table-column
          align="right"
          class-name="bg-primary-light"
          prop="cost"
          width="110"
          label="Price">
        </el-table-column>
        <el-table-column
          width="70"
          align="center"
          label="BTB">
            <template #default="scope">
              <i class="mdi mdi-check fs-20 success-text" v-if="scope.row.isBackToBack"></i>
            </template>
        </el-table-column>
        <el-table-column
          width="70"
          align="center"
          label="Double">
            <template #default="scope">
              <i class="mdi mdi-check fs-20 success-text" v-if="scope.row.isDoubleSession"></i>
            </template>
        </el-table-column>
        <el-table-column
          width="70"
          align="center"
          label="Book">
            <template #default="scope">
              <i class="mdi mdi-check fs-20 success-text" v-if="scope.row.isBooking"></i>
            </template>
        </el-table-column>
        <el-table-column
          width="70"
          align="center"
          label="Refund">
            <template #default="scope">
              <i class="mdi mdi-check fs-20 success-text" v-if="scope.row.isRefund"></i>
            </template>
        </el-table-column>
      </el-table>
    </div>

    <app-dialog-export
      @close="dialogExportVisible = false"
      :dialogExportVisible="dialogExportVisible"></app-dialog-export>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AppDialogExport from './ExportTickets.vue'

export default {
  name: 'TicketsReport',

  components: {
    AppDialogExport
  },

  data () {
    return {
      dateSearch: new Date(),
      sessionSearch: '',
      search: '',
      dialogExportVisible: false,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() ||
           time.getTime() + (1000 * 60 * 60 * 24 * 30) < Date.now()
        },
        shortcuts: [{
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: 'Yesterday',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '2 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 2)
            picker.$emit('pick', date)
          }
        }, {
          text: '3 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 3)
            picker.$emit('pick', date)
          }
        }, {
          text: '4 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 4)
            picker.$emit('pick', date)
          }
        }, {
          text: '5 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 5)
            picker.$emit('pick', date)
          }
        }, {
          text: '6 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 6)
            picker.$emit('pick', date)
          }
        }, {
          text: '7 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      }
    }
  },

  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight,
      innerWidth: state => state.nav.innerWidth,
      tickets: state => state.ticket.tickets
    }),
    ...mapGetters(['invoices', 'sessionsByDay']),
    tableHeight () {
      return this.innerHeight - 181
    },
    tableWidth () {
      return this.innerWidth - 30
    },
    selectDay () {
      return this.formatDate(this.dateSearch)
    },
    sessionsForSelect () {
      return [{ label: 'All sessions', value: '' }]
        .concat(this.sessionsByDay[this.selectDay].map((s, index) => {
          return { label: s.time, value: index }
        }))
    },
    filterBySessions () {
      return this.tickets.filter(t => this.sessionSearch === '' || t.sessionIds.indexOf(this.sessionSearch) !== -1)
    },
    filteredByDate () {
      return this.filterBySessions.filter(i => this.formatDate(i.day) === this.selectDay)
    },
    filtered () {
      return this.filteredByDate.filter(item => {
        return this.search.length < 2 ||
         (item.hasOwnProperty('clientNames') && item.clientNames.some(c => c.toLowerCase().indexOf(this.search.toLowerCase()) !== -1))
      })
    }
  },

  methods: {
    ...mapActions(['getTicketsByDate']),
    tableRowClassName ({ row, rowIndex }) {
      if (row.isRefund) {
        return 'locked-panel'
      }
    },
    onChangeDate (v) {
      this.getTicketsByDate(this.formatDate(this.dateSearch))
    },
    formatDate (date) {
      return new Date(new Date(date).toString().split('GMT')[0] + ' UTC')
        .toISOString().slice(0, 10)
    },
    formatDateTime (date) {
      if (date !== undefined) {
        let d = new Date(date)
        let minutes = d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes()
        let hours = d.getHours()
        return this.formatDate(date) + '   ' + hours + ':' + minutes
      } else {
        return ''
      }
    },
    handleSearchInput () {},
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        const values = data.filter(d => !d.isRefund).map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  },
  mounted () {
    this.dateSearch = new Date()
  }
}
</script>

<style lang="scss">
</style>
